.main{
    position: absolute;
    width:100%;
    height:100vh;
    top: 0;
    left:0;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.arrow{
    margin:0 20px;
}
.arrow svg{
    fill:#3fbc69;
    width:40px;
    height:40px;
}
.arrow2{
    transform:translateX(18px)
}
.arrow p{
    font-size:1em;
    color:#3fbc69;
}
.arrowdisabled svg{
    fill:rgb(158, 199, 181)
}
.arrowdisabled p{
    color:rgb(158, 199, 181);
}
.arrowtext1{
    margin-left:-4px;
}
.arrowtext2{
    margin-left:7px;
}
@media(max-width:650px){
    .arrow svg{
        width:30px;
        height:30px;
    }
    .arrow2{
        transform:translateX(13px)
    }
    .arrowtext1{
        margin-left:-10px;
    }
    .arrowtext2{
        margin-left:-5px;
    }
}
.counters{
    display:flex;
    margin-bottom:20px;
    justify-content:center;
    align-items:center;
}
.counters > div{
    display:flex;
}
.counters > div div{
    border-radius:60px;
    padding:10px 15px;
    display:flex;
    margin:0 5px;
    justify-content:space-between;
    align-items:center;
}
.counters > div:nth-child(1) div:nth-child(2){
    background:#EA3F31;
}
.counters > div:nth-child(2) :nth-child(2){
    padding:0
}
.counters > div:nth-child(2) :nth-child(2) img{
    width:60px;
}
.counters img{
    width:25px;
}
.counters p{
    color:#fff;
    margin-left:20px;
}
.counters > div:first-of-type div:first-of-type{
    background:#2BA84C;
}
.counters > div:nth-child(2) div:nth-child(1){
    background:#EBB108;
}
.counters > div div:nth-child(4) > img:first-of-type{
    width:35px;
}
@media(max-width:570px){
    .main{
        width:100%;
    }
}
@media(max-width:560px){
    .counters > div:nth-child(2) :nth-child(2) img{
        width:37px;
    }
    .counters img{
        width:17px;
    }
    .counters > div div:nth-child(4) > img:first-of-type{
        width:27px;
    }
    .counters > div div{
        flex-direction: column;
        padding:7px;
        margin:5px 0;
        width:fit-content;
    }
    .counters p{
        margin:0;
    }
    .counters{
        position: absolute;
background: white;
border-radius: 20px;
padding: 5px;
bottom: 10px;
left:10px;
flex-direction: column;
margin:0;
border: 2px solid #eee;
    }
    .counters > div{
        margin:0;
        justify-content:center;
        flex-direction: column;
        align-items:center;
    }
    
    .counters p{
        margin:0;
    }
}
@media(max-height:610px){
    .counters > div:nth-child(2) :nth-child(2) img{
        width:37px;
    }
    .counters img{
        width:17px;
    }
    .counters > div div:nth-child(4) > img:first-of-type{
        width:27px;
    }
    .counters > div div{
        flex-direction: column;
        padding:7px;
        margin:5px 0;
        width:fit-content;
    }
    .counters p{
        margin:0;
    }
    .counters{
        position: absolute;
background: white;
border-radius: 20px;
padding: 5px;
bottom: 10px;
left:10px;
flex-direction: column;
margin:0;
border: 2px solid #eee;
    }
    .counters > div{
        justify-content: center;
        margin:0;
        align-items:center;
        flex-direction: column;
    }
}
@media(max-height:340px){
    .counters{

    }
}