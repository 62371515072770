.main{
    width:300px;
    margin:auto;
    height:calc(100vh - 40px);
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
}

.main img{
    width:100%;
    margin-bottom:10px;
}
.main p{
    text-align:center;
}
.main button{
    background:linear-gradient(180deg, rgba(0,99,150,1) 0%, rgba(18,146,172,1) 100%);
    border:none;
    border-radius:10px;
    padding:8px 100px;
    color:#fff;
    font-family:'Gordita Regular';
    font-size:1.2em;
    margin-top:10px;
}
@media(max-width:533px){
    .main{
        width:100%;
        padding:0 20px;
    }
}
.arrow{
    width:30px;
    height:30px;
    position:absolute;
    top:20px;
    left:20px;
}
.arrow svg{
    width:100%;
    height:100%;
}