.logocontainer{
    width:250px;
}
.logocontainer img{
    width:100%;
}
.timer{
    display:flex;
    margin:10px 0;
    background:#fafafa;
    padding:7px;
    border-radius:100px;
    justify-content:space-between;
    align-items:center;
}
.timer svg{
    width:30px;
    height:30px;
    fill:#c5c5c5;
    margin-right:5px;
}
.timer p{
    margin-right:4px;
}
.main{
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
}
.input > div{
    flex-wrap: wrap;
    justify-content:center;
}
.letter, .input > div div{
    width:30px;
    height:30px;
    margin:3px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:100px;
    padding:20px;
}
.letter{
    font-size:1.05em;
    border:none;
    background:linear-gradient(180deg,#fd7056,#fe9253);
}
.letter p, .input div p{
    text-transform: uppercase;
    font-size:1.2em;
    
    font-family:'Gordita Bold'
}
.letter p{
    color:#000;
}
.letters{
    width:350px;
    display:flex;
    justify-content:center;
    flex-wrap: wrap;
    align-items: center;
}
.input{
    display:flex;
    margin-top:10px;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
}
.input > div div{
    background:#dfdfdf;
}
.letter[data-disabled="true"]{
    background:rgb(211, 211, 211);
}
.letter[data-disabled="true"] p{
    color:#fff;
}
.timersolved{
    background:rgb(12, 165, 12);
}

.timererrored{
    background:rgb(197, 1, 1);
}
.timersolved p, .timererrored p{
    color:#fff;
}
.timersolved svg, .timererrored svg{
    fill:#fff;
}
@media(max-width:920px){
    .input{
        width:350px;
    }
}
@media(max-width:650px){
    .letter, .input > div div{
        width:40px;
        height:40px;
        padding:14px;
        margin:3px;
    }
    .letter p, .input div p{
        font-size:1em;
        width:100%;
    }
    .logocontainer{
        width:150px;
    }
}
@media(max-width:520px){
    .main, .letters{
        width:100%;
    }
    .letter p{
        margin-left:-4px;
    }
    .input{
        width:auto;
    }
}
@media(max-width:390px){
    .letter p, .input div p{
        font-size:1em;
    }
}
@media(max-height:640px){
    .logocontainer{
        width:150px;
    }
}
@media(max-height:610px){
    .logocontainer{
        width:100px;
    }
    .letter, .input > div div{
        width:35px;
        height:35px;
        padding:14px;
        margin:3px;
    }
    .letter p, .input div p{
        font-size:1em;
    }
    .logocontainer{
        width:150px;
    }
}