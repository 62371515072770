@font-face {
  font-family: 'Gordita Regular';
  src: url('./fonts/Gordita-Regular.ttf');
}
@font-face {
  font-family: 'Gordita Bold';
  src: url('./fonts/Gordita-Bold.ttf');
}
:root{
  --brand:#006396;
}
body{
  margin:0;
  padding:10px;
}
p{
  margin:0;
  line-height: 1.4em;
  font-size: 1.2em;
  font-family: 'Gordita Regular',sans-serif;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 100;
  margin:0;
  font-family: 'Gordita Bold',Arial;
}

*{
          appearance: none;
          box-sizing:border-box;
  -webkit-tap-highlight-color: transparent;
}
::selection{
  color:#fff;
  background:var(--brand);
}