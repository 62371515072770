.main{
    border:2px solid #f4f4f4;
    border-radius:100px;
    padding:5px 7px;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.main img{
    margin-right:5px;
    width:20px;
    height:20px;
}
.main p{
    font-size:1.2em;
}

@media(max-width:355px){
    .main p{
        font-size:1.1em;
    }
}