.main{
    position: absolute;
    width:100%;
    height:fit-content;
    background:#fff;
    top: 0;
    z-index:2;
    left: 0;
    box-shadow: 2px 0px 6px rgba(0,0,0,0.3);
    padding:12px;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.backbutton{
    width:35px;
    height:35px;
}
.backbutton svg{
    width:100%;
    height:100%;
}
@media(max-width:455px){
    .main img{
        width:200px;
    }
}
@media(max-width:350px){
    .main img{
        width:150px;
    }
}